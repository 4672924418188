import * as serviceWorker from "src/serviceWorker";
if (window.location.pathname?.endsWith("callback")) {
  import("src/entries/callback");
} else if (window.location.pathname?.endsWith("silent_review")) {
  import("src/entries/silent_renew");
} else {
  import("src/entries/main");
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
